import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const CaseStudy = ({ data }) => {
  const piece = data.allContentfulPortfolioPiece.edges[0].node;

  return (
    <Layout page="casestudy" piece={piece}>
      <div className="wrapper-big">
        <div className="case-study">
          <div className="case-study__image">
            <Img fluid={piece.pieceImage.fluid} />
          </div>
  
          <div className="case-study__info">
            <h3 className="case-study__subheading">Overview</h3>
            
            <p className="case-study__description">{piece.caseStudyProjectInfo.internal.content}</p>
  
            <h3 className="case-study__subheading">Project Highlights</h3>
  
            <ul className="case-study__highlight-list">
            {
              piece.caseStudyProjectHighlights.internal.content.split('|').map((point, i) => {
                return (
                  <li className="case-study__highlight-item" key={i}>{point}</li>
                )
              })
            }
            </ul>

            <a href={piece.pieceLink} target="_blank" rel="noopener noreferrer" className="case-study__link">View Live</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CaseStudyPieces($slug: String!) {
    allContentfulPortfolioPiece(
      filter: {pieceSlug: { eq: $slug }}
    ) {
      edges {
        node {
          pieceTitle
          pieceSlug
          pieceLink
          pieceHasCasestudy
          pieceImage {
            fluid(quality: 100, maxWidth: 700) {
              ...GatsbyContentfulFluid
            }
          }
          pieceDescription {
            pieceDescription
          }
          caseStudyProjectInfo {
            internal {
              content
            }
          }
          caseStudyProjectHighlights {
            internal {
              content
            }
          }
        }
      }
    }
  }
`

export default CaseStudy